<template>
  <div class="settings columns__column-2">
    <div class="settings__prompt">
      Заполните личную информацию профиля, для автоматического заполнения форм в
      разделе Формы
    </div>
    <form action="#" class="settings__form form">
      <div class="form__flex">
        <Input
          @value-input="saveValue"
          title="Email"
          field="email"
          type="email"
          placeholder="test@imigrata.com"
          custom_class="form__email"
          :error="errors.email"
          :value="values.email"
        />
        <div class="form__change-button" @click.prevent="changeEmail">
          Сменить
        </div>
      </div>
      <div class="form__flex">
        <Phone
          title="Номер телефона"
          @value-input="saveValue"
          field="phone"
          :error="errors.phone"
          :value="values.phone"
        />
        <div
          class="form__change-button"
          @click.prevent="initPhoneChange(false)"
        >
          Сменить
        </div>
      </div>
      <div v-if="wait_code">
        <div class="form__flex">
          <Input
            @value-input="saveValue"
            title="Код из SMS"
            field="code"
            type="text"
            placeholder="1234"
            custom_class="form__text"
            :error="errors.code"
          />
          <div
            class="form__change-button"
            v-show="values.code.length >= 4"
            @click.prevent="confirmPhoneChange"
          >
            Подтвердить
          </div>
        </div>
        <div class="form__loader">
          <picture v-show="timer > 0">
            <source srcset="/img/Settings/loader.svg" type="image/webp" />
            <img src="/img/Settings/loader.svg" alt="loader" />
          </picture>
          <p class="form__loader-text">
            <span v-show="timer > 0">{{ timer }} сек</span>
            <a
              v-show="timer === 0"
              href="#"
              @click.prevent="initPhoneChange(true)"
              >Запросить заново</a
            >
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { API } from "../../../helpers/api";
import { Validation } from "../../../helpers/validation";

import Input from "../../../components/utils/Input.vue";
import Phone from "../../../components/utils/Phone.vue";

export default {
  props: ["settings"],
  name: "ProfileSettingsCommon",
  components: {
    Input,
    Phone,
  },
  data() {
    const old_email = this.settings.email;
    const old_phone = this.settings.phone;

    return {
      values: {
        email: old_email,
        phone: old_phone,
        code: "",
      },
      errors: {
        email: "",
        phone: "",
        code: "",
      },
      confirm_phone: "",
      wait_code: false,
      timer: 45,
      timer_id: null,
    };
  },
  methods: {
    saveValue(data) {
      this.values[data.field] = data.value;
      this.errors[data.field] = "";
    },
    async changeEmail() {
      if (this.settings.email === this.values.email) {
        this.errors.email = "Данная почта уже используется";
        return;
      }

      this.errors.email = Validation.checkEmail(this.values.email);

      if (Object.values(this.errors).some((result) => result.length > 0))
        return;

      try {
        const response = await API.send(
          "changeEmail",
          {
            new_email: this.values.email,
          },
          "POST"
        );
        if (response.status !== 0) {
          this.errors.email = response.user_message;
          return;
        }

        alert(
          "Почта изменена. Для подтверждения перейдите по ссылке в отправленном письме."
        );
      } catch (e) {
        this.errors.email = "Неизвестная ошибка. Попробуйте позже.";
      }
    },
    async initPhoneChange(is_resend) {
      if (!is_resend) {
        if (this.settings.phone === this.values.phone) {
          this.errors.phone = "Данный телефон уже используется";
          return;
        }

        this.errors.phone = Validation.checkPhone(this.values.phone);

        if (Object.values(this.errors).some((result) => result.length > 0))
          return;

        this.confirm_phone = this.values.phone;
      }

      try {
        const response = await API.send(
          "changePhone",
          {
            new_phone: this.confirm_phone,
          },
          "POST"
        );
        if (response.status !== 0) {
          this.errors.phone = response.user_message;
          return;
        }

        console.log(response);

        alert(
          "На заданный номер телефона отправлено СМС с кодом подтверждения"
        );
        this.wait_code = true;
        this.initTimer();
      } catch (e) {
        this.errors.phone = "Неизвестная ошибка. Попробуйте позже.";
      }
    },
    async confirmPhoneChange() {
      try {
        const response = await API.send(
          "confirmPhone",
          {
            code: this.values.code,
          },
          "POST"
        );
        if (response.status !== 0) {
          this.errors.code = response.user_message;
          return;
        }

        alert("Телефон подтвержден!");

        this.wait_code = false;
      } catch (e) {
        this.errors.code = "Неизвестная ошибка. Попробуйте позже.";
      } finally {
        this.values.code = "";
      }
    },
    initTimer() {
      if (this.timer_id) {
        clearInterval(this.timer_id);
      }

      this.timer = 45;
      this.timer_id = setInterval(() => {
        this.timer--;

        if (this.timer === 0) {
          clearInterval(this.timer_id);
        }
      }, 1000);
    },
  },
};
</script>

<style>
</style>