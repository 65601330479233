<template>
  <div class="notifications columns__column-2">
    <div class="settings__prompt">
      Заполните личную информацию профиля, для автоматического заполнения форм в
      разделе Формы
    </div>
    <form class="notifications__form">
      <Checkbox
        @value-input="saveValue"
        id="get_email_mailing"
        field="get_email_mailing"
        title="Получать рассылку на email"
        :value="!!settings.get_email_mailing"
        :is_ajax="true"
        ajax_endpoint="updateProfileSettings"
      />
      <Checkbox
        @value-input="saveValue"
        id="get_sms_mailing"
        field="get_sms_mailing"
        title="Получать рассылку по SMS"
        :value="!!settings.get_sms_mailing"
        :is_ajax="true"
        ajax_endpoint="updateProfileSettings"
      />
    </form>
  </div>
</template>

<script>
import Checkbox from "../../../components/utils/Checkbox.vue";

export default {
  props: ["settings"],
  name: "ProfileSettingsSubscriptions",
  components: {
    Checkbox,
  },
};
</script>