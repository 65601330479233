<template>
  <div class="notifications columns__column-2">
    <div class="settings__prompt">
      Заполните личную информацию профиля, для автоматического заполнения форм в
      разделе Формы
    </div>
    <form class="notifications__form">
      <Checkbox
        id="get_system_notifications"
        field="get_system_notifications"
        title="Получать системные уведомления"
        :value="!!settings.get_system_notifications"
        :is_ajax="true"
        ajax_endpoint="updateProfileSettings"
      />
      <Checkbox
        id="get_new_messages_notificiations"
        field="get_new_messages_notificiations"
        title="Получать уведомления о новых сообщениях в чате"
        :value="!!settings.get_new_messages_notificiations"
        :is_ajax="true"
        ajax_endpoint="updateProfileSettings"
      />
      <Checkbox
        id="get_order_status_change_notifications"
        field="get_order_status_change_notifications"
        title="Получать уведомления о смене статуса заказа"
        :value="!!settings.get_order_status_change_notifications"
        :is_ajax="true"
        ajax_endpoint="updateProfileSettings"
      />
    </form>
  </div>
</template>

<script>
import Checkbox from "../../../components/utils/Checkbox.vue";

export default {
  props: ["settings"],
  name: "ProfileSettingsNotifications",
  components: {
    Checkbox,
  },
};
</script>

<style>
</style>