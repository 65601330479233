<template>
  <div class="security columns__column-2">
    <div class="settings__prompt">
      Заполните личную информацию профиля, для автоматического заполнения форм в
      разделе Формы
    </div>
    <form class="security__form">
      <Password
        title="Введите старый пароль"
        @value-input="saveValue"
        field="old_password"
        :error="errors.old_password"
      />
      <Password
        title="Введите новый пароль"
        @value-input="saveValue"
        field="new_password"
        :error="errors.new_password"
      />
      <Password
        title="Введите новый пароль еще раз"
        @value-input="saveValue"
        field="new_password_confirm"
        :error="errors.new_password_confirm"
      />
      <button class="security__change-button" @click.prevent="sendForm">
        Сменить
      </button>
    </form>
  </div>
</template>

<script>
import { API } from "../../../helpers/api";
import { Validation } from "../../../helpers/validation";

import Password from "../../../components/utils/Password.vue";

export default {
  props: ["settings"],
  name: "ProfileSettingsSubscriptions",
  components: {
    Password,
  },
  data() {
    return {
      values: {
        old_password: "",
        new_password: "",
        new_password_confirm: "",
      },
      errors: {
        old_password: "",
        new_password: "",
        new_password_confirm: "",
      },
    };
  },
  methods: {
    saveValue(data) {
      this.values[data.field] = data.value;
      for (const key in this.errors) {
        this.errors[key] = "";
      }
    },
    async sendForm() {
      this.errors.old_password = Validation.checkPassword(
        this.values.old_password
      );
      this.errors.new_password = Validation.checkPassword(
        this.values.new_password
      );
      this.errors.new_password_confirm =
        this.values.new_password === this.values.new_password_confirm
          ? ""
          : "Пароли не совпадают";

      if (Object.values(this.errors).some((result) => result.length > 0))
        return;

      try {
        const response = await API.send("changePassword", this.values, "POST");

        if (response.status !== 0) {
          this.errors.old_password = response.user_message;
          return;
        }

        alert("Пароль обновлен успешно");
      } catch (e) {
        this.errors.old_password = "Неизвестная ошибка. Попробуйте позже.";
      }
    },
  },
};
</script>